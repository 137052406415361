<template>
	<div class="diagntable">
		<el-table :data="BrandShopList" style="width: 100%" show-summary>
			<el-table-column prop="date" label="商品主图"  width="120">
				<template slot-scope="scope">
					<img :src="scope.row.shop_img" alt="" width="100" height="60">
				</template>
			</el-table-column>
			<el-table-column prop="date" label="商品信息" width="200">
				<template slot-scope="scope">
					<a :href="scope.row.shop_link" class="spxinxi" target="_blank" v-if="scope.row.highlight" v-html="scope.row.highlight.shop_title[0]"></a>
					<a :href="scope.row.shop_link" class="spxinxi" target="_blank" v-else v-html="scope.row.shop_title"></a>
				</template>
			</el-table-column>
			<el-table-column prop="date" label="店铺名">
				<template slot-scope="scope">
					<div class="wentext" style="margin-top: 10px;"><span>{{scope.row.store_name}}</span></div>
				</template>
			</el-table-column>
			<el-table-column prop="date" label="品牌名">
				<template slot-scope="scope">
					<div class="wentext" style="margin-top: 10px;"><span>{{scope.row.product_format ? fangfa(scope.row.product_format) : '无'}}</span></div>
				</template>
			</el-table-column>
			<el-table-column prop="date" label="价格">
				<template slot-scope="scope">
					<div class="pricetext">
						<span style="margin-right:5px; font-size:13px;" v-if="scope.row.price != scope.row.price_max">¥{{scope.row.price}}~¥{{scope.row.price_max}}</span>
						<span style="margin-right:5px;" v-else>¥{{scope.row.price}}</span>
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="today_volume" label="近24小时" sortable width="140px">
				<template slot="header">
					<span  style="margin-right:5px;">近24小时</span>
					<el-tooltip placement="top">
					<div slot="content">昨日0点到今日0点销量</div>
					<i class="iconfont icon-wenhao item dianji"></i>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column prop="three_total" label="前3天" sortable >
				<template slot-scope="scope">
					{{scope.row.three_total}}
				</template>
			</el-table-column>

			<el-table-column prop="seven_total" label="前7天" sortable >
				<template slot-scope="scope">
					{{scope.row.seven_total}}
				</template>
			</el-table-column>
			<el-table-column prop="sales_volume" label="总销量" sortable >
				<template slot-scope="scope">
					{{scope.row.sales_volume}}
				</template>
			</el-table-column>
		</el-table>
		<div class="tijkwai">
			<div class="tijiaok">
				<h2>为了更准确诊断账户，请您选填一下数据</h2>
				<div class="tjfrome">
					<div class="kuai"><span class="text">商家体验分：</span><el-input placeholder="请输入内容" v-model="input1" clearable></el-input></div>
					<div class="kuai"><span class="text">商家店铺等级：</span><el-input placeholder="请输入内容" v-model="input2" clearable></el-input></div>
					<div class="kuai"><span class="text">商家48小时发货率：</span><el-input placeholder="请输入内容" v-model="input3" clearable></el-input></div>
					<div class="kuai"><span class="text">账户有无违规情况：</span>
						<el-select v-model="input4" placeholder="请选择">
							<el-option v-for="(item,key) in weiguioptions" :key="key"  :value="item"></el-option>
						</el-select>
					</div>
				</div>
				<div class="tjanniu">
					<el-button type="primary" round @click="onJianyitj">确认提交</el-button>
					<el-button type="info" class="infobutton" round @click="onSkip">跳过不写</el-button>
				</div>
			</div>
		</div>
		
		<el-dialog title="联系客服" :visible.sync="tijiaole" width="700px" custom-class="diagonDialog" modal-append-to-body>
			<div class="tiaobiao">
				<div class="erweimaa"><img width="100%" src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/xinweijie.jpg" /></div>
				<p class="text">扫码添加，帮您更准确的诊断账户信息</p>
			</div>
		</el-dialog>
	</div>
</template>
  
  
<script>
export default {
	props:{
		BrandShopList:{
			default: () => [],
            type: Array,
		},
		summary:null,
	},
	data() {
		return {
			input1:'',
			input2:'',
			input3:'',
			input4:'',
			weiguioptions:['有','无'],
			tijiaole:false,
		};
	},
  
  
	mounted(){
		
	},
	methods: {
		onJianyitj(){
			if(this.input1.length || this.input2.length || this.input3.length || this.input4.length){
				this.tijiaole = true
				var param = {
					merchant_points:this.input1 + ',' + this.input2 + ',' + this.input3 + ',' + this.input4,
				}
				this.$service.post(this.$api.limitMerchants,param, (res)=> {
					if(res.code == 200){
						this.$message({ message: '提交成功', type: 'success'});
					}
				})
			}else{
				this.tijiaole = true
			}
		},
		onSkip(){//跳过不写
			this.tijiaole = true
		}
	}
};
</script>
<style lang="scss" >
.diagntable{
	.el-table{
		width: 1200px !important;
		margin: 0 auto;
		box-shadow: 0 0 5px 1px #ededed;
	}
	.tijkwai{
		margin-top: 30px;
		background: url(https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/diagnose5.jpg) no-repeat;
		padding-top: 20px;
	}
	.tijiaok{
		background: #fff;
		width: 800px;
		margin: 40px auto;
		margin-bottom: 0;
		padding: 30px 150px;
		box-shadow: 0 0 15px -6px #182458;
		.infobutton{
					background: #dadada !important;
					border-color: #dadada !important;
				}
		h2{
			margin-bottom: 40px;
			text-align: center;
			color: #005aff;
		}
		.kuai{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			.el-input{
				width: 330px;
				margin-left: 10px;
				.el-input__inner{
					height: 35px;
				}
				
			}
			.text{
				width: 150px;
				height: 35px;
				line-height: 35px;
				font-size: 16px;
				color: #333;
				text-align-last: justify;
			}
		}
		.tjanniu{
			padding-top: 20px;
			display: flex;
			justify-content: center;
			.el-button{
				margin: 0 10px;
			}
		}
	}
}
</style>
