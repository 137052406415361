<template>
	<div class="diagnose">
		<app-HeadTop ref="HeadTop"></app-HeadTop>
		<div class="diagnose">
			<div class="brandBanner">
				<div class="text">
					<h1>限流诊断</h1>
				</div>
				<div class="search">
					<div class="search-nei">
						<div class="search-one">
							<el-input placeholder="请输入需要限流诊断的商品链接地址" autocomplete="on" v-model="rand_name" @keyup.enter.native="toSearch"></el-input>
							<div class="chaxun" @click="toSearch()"><span>{{logindaa ? '分析准备中...' : '点击开始分析'}}</span></div>
						</div>
						<div class="bannerxia">
							<div class="soutishi" v-if="dsr_accounted.shop_text && !jialoading">1、{{dsr_accounted.shop_text}}</div>
							<div class="soutishi" v-if="dsr_accounted.text && !jialoading">2、{{dsr_accounted.text}}</div>
						</div>
						<div class="sfjjwtban" v-if="!jialoading && DsrList.dsr"><Proposal ref="Proposal"></Proposal></div>
					</div>
				</div>
			</div>
			<div class="jiazaiz" v-if="logindaa2">
				<div class="sceneasdq">
					<div class="text">分析准备中...</div>
					<el-progress :text-inside="true" :stroke-width="20" :percentage="0" status="exception"></el-progress>
				</div>
			</div>
			<div class="jiazaiz" v-if="!DsrList.dsr && !logindaa2" style="padding-top:200px">
				<div class="sceneasdq">
					<div class="text">请输入诊断商品链接开始分析诊断</div>
					<!-- <el-progress :text-inside="true" :stroke-width="20" :percentage="0" status="exception"></el-progress> -->
				</div>
			</div>
			
			<div class="brandContent" v-if="!jialoading && DsrList.dsr">
				<!-- <div class="brandbiaoge"> -->
				<div class="brandbiaoge">
					<!-- <div class="biaotiq">商标信息</div> -->
					<div class="shangbiao">
						<div class="biaoge">
							<div class="dan">
								<div class="topico">
									<div class="tubiao"><i class="iconfont icon-paiming"></i></div>
									<div class="shuju">
										<div class="top-title">好评率</div>
										<div class="num">{{DsrList.comments_value}}%</div>
									</div>
								</div>
								<div class="accfor" v-if="DsrList.comments_value">
									<div class="acctitle">当前好评率为{{DsrList.comments_value}}%</div>
									<el-progress :percentage="DsrList.comments_value*1" :show-text='false' color="#eee"></el-progress>
								</div>
							</div>
							<div class="dan">
								<div class="topico">
									<div class="tubiao"><i class="iconfont icon-RectangleCopy2"></i></div>
									<div class="shuju">
										<div class="top-title">物流体验</div>
										<div class="num">{{DsrList.dsr.logistics}}</div>
									</div>
								</div>
								<div class="accfor" v-if="dsr_accounted.accounted_logistics">
									<div class="acctitle">当前评分超过约{{dsr_accounted.accounted_logistics}}%的商家</div>
									<el-progress :percentage="dsr_accounted.accounted_logistics*1" :show-text='false' color="#eee"></el-progress>
								</div>
							</div>
							<div class="dan">
								<div class="topico">
									<div class="tubiao"><i class="iconfont icon-RectangleCopy"></i></div>
									<div class="shuju">
										<div class="top-title">商品体验</div>
										<div class="num">{{DsrList.dsr.product}}</div>
									</div>
								</div>
								<div class="accfor" v-if="dsr_accounted.accounted_logistics">
									<div class="acctitle">当前评分超过约{{dsr_accounted.accounted_product}}%的商家</div>
									<el-progress :percentage="dsr_accounted.accounted_product*1" :show-text='false' color="#eee"></el-progress>
								</div>
							</div>
							<div class="dan">
								<div class="topico">
									<div class="tubiao"><i class="iconfont icon-RectangleCopy1"></i></div>
									<div class="shuju">
										<div class="top-title">商家服务</div>
										<div class="num">{{DsrList.dsr.shop}}</div>
									</div>
								</div>
								<div class="accfor" v-if="dsr_accounted.accounted_logistics">
									<div class="acctitle">当前评分超过约{{dsr_accounted.accounted_shop}}%的商家</div>
									<el-progress :percentage="dsr_accounted.accounted_shop*1" :show-text='false' color="#eee"></el-progress>
								</div>
							</div>
						</div>
					</div>	
					<div class="biaotiq">当前商品</div>
					<div class="shangpin dangqian">
						<el-table :data="BrandDandata" style="width: 100%" >
							<el-table-column prop="date" label="商品主图"  width="120">
								<template slot-scope="scope">
									<img :src="scope.row.shop_img" alt="" width="100" height="60">
								</template>
							</el-table-column>
							<el-table-column prop="date" label="商品信息" width="200">
								<template slot-scope="scope">
									<a :href="scope.row.shop_link" class="spxinxi" target="_blank" v-html="scope.row.shop_title"></a>
								</template>
							</el-table-column>
							<el-table-column prop="date" label="店铺名">
								<template slot-scope="scope">
									<div class="wentext" style="margin-top: 10px;"><span>{{scope.row.store_name}}</span></div>
								</template>
							</el-table-column>
							<el-table-column prop="date" label="品牌名">
								<template slot-scope="scope">
									<div class="wentext" style="margin-top: 10px;"><span>{{scope.row.product_format ? fangfa(scope.row.product_format) : '无'}}</span></div>
								</template>
							</el-table-column>
							<el-table-column prop="date" label="价格">
								<template slot-scope="scope">
									<div class="pricetext">
										<span style="margin-right:5px; font-size:13px;" v-if="scope.row.price != scope.row.price_max">¥{{scope.row.price}}~¥{{scope.row.price_max}}</span>
										<span style="margin-right:5px;" v-else>¥{{scope.row.price}}</span>
									</div>
								</template>
							</el-table-column>

							<el-table-column prop="today_volume" label="近24小时">
								<template slot="header">
									<span  style="margin-right:5px;">近24小时</span>
									<el-tooltip placement="top">
									<div slot="content">昨日0点到今日0点销量</div>
									<i class="iconfont icon-wenhao item dianji"></i>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column prop="three_total" label="前3天">
								<template slot-scope="scope">
									{{scope.row.three_total}}
								</template>
							</el-table-column>

							<el-table-column prop="seven_total" label="前7天">
								<template slot-scope="scope">
									{{scope.row.seven_total}}
								</template>
							</el-table-column>
							<el-table-column prop="sales_volume" label="总销量">
								<template slot-scope="scope">
									{{scope.row.sales_volume}}
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="biaotiq">关联商品</div>
					<div class="shangpin tablexia">
						<Table ref="Table" :BrandShopList='BrandShopList'></Table>
					</div>
				</div>
				<div class="saimalx">
					<!-- <p class="erweima"><img width="200" src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/zhaotianyuan.jpg" alt=""></p>
					<p><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/diagnose3.jpg" alt=""></p> -->
				</div>
				<!-- <Details ref="Details" v-else></Details> -->
			</div>
			
			<div class="saimalx"  v-else-if="!jialoading">
				<!-- <p class="erweima"><img width="200" src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/zhaotianyuan.jpg" alt=""></p>
				<p><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/diagnose4.jpg" alt=""></p> -->
			</div>
			
			<div class="jiazaiz" v-if="jialoading" :textwen="'正在分析...'"><Jialoading ref="Jialoading" :time='time'></Jialoading></div>
			<div class="saimalx" v-if="jialoading" >
				<!-- <p class="erweima"><img width="200" src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/zhaotianyuan.jpg" alt=""></p>
				<p><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/diagnose2.jpg" alt=""></p> -->
			</div>
			<!-- 底部按钮点击 -->
			<!-- <div class="zaiyonon" v-if="!jialoading && DsrList.logistics">
				<p class="text-js">更多诊断信息，请联系客服 </p>
				<span class="anniu"  @click="kefutan = true">立即咨询</span>
			</div> -->
			
		</div> 
	</div>
</template>

<script>
import Jialoading from './loading'
import Table from './table'
import Proposal from './proposal'
import HeadTop from '@/views/Allpubilc/headTop';
export default {
	data () {
		return {
			logindaa:false,
			logindaa2:false,
			onShow: false,//切换
			DsrList: {},//dsr数据分析
			BrandShopList: [],//商标对应商品
			BrandDandata: [],//查询的商品
			dsr_accounted:{},
			jialoading:false,
			lvtotal2:null,
			rand_name:'',//搜索框
			carouselList1:['155*****312，10分钟前查询了商标','156*****888，8分钟前查询了商标','139*****212，5分钟前查询了商标','189*****991，2分钟前查询了商标'],
			carouselList2:['133*****233，20分钟前查询了商标','176*****199，32分钟前查询了商标','155*****188，17分钟前查询了商标','133*****891，1分钟前查询了商标'],
			qiehuan:'',
			liveShoploading:false,
			order_type:"",
			field:"",
			time:Math.round(Math.random()*7000+3000),
			// time:Math.round(Math.random()*7000+3000)
		}
	},
	components:{
		'app-HeadTop':HeadTop,
		Jialoading,
		Table,
		Proposal,
	},
	mounted(){
	},

	methods: {
		toSearch(){
			if(this.rand_name != ''){
				this.qiehuan = true
				this.onBrandInfoData(1)
			}else{
				this.$message({ message: '请输入需要限流诊断的商品链接地址', type: 'warning'});
			}
			
		},
		
		//商标详细信息
		onBrandInfoData(){
			if(!this.jialoading){//检查是否已经点击过按钮诊断
				this.logindaa = true
				this.logindaa2 = true
				// this.time = 100
				this.time = Math.round(Math.random()*7000+3000)
				this.BrandInfoList = []
				this.dsr_accounted = {}
				this.DsrList = {}
				this.BrandShopList = []
				this.BrandDandata = []
				var prame = {
					// shop_link:'https://haohuo.jinritemai.com/views/product/item?id=3442997311809169940',
					shop_link:this.rand_name,
				}
				this.$service.get(this.$api.getLimitDiagnosis,prame, (res)=> {
					if(!res){
						this.logindaa2 = false
						this.logindaa = false
					}if(res.code == 200){
						this.logindaa2 = false
						this.sfjiejue = true
						this.jialoading = true
						this.DsrList = res.data.dsr
						console.log(this.DsrList)
						this.BrandShopList = res.data.shop_list
						this.BrandDandata = res.data.this_shop
						this.dsr_accounted = res.data.dsr_accounted
						setTimeout(() =>{
							this.jialoading = false
							this.logindaa = false
						},this.time)
					}
				})
			}else{
				this.$message({ message: '正在诊断请稍等', type: 'warning'});
			}
		},
		//未解决问题提交
		toShow(){
			// this.$parent.onShow()
			this.$refs.Proposal.onShow()
		}
	}
}
</script>

<style lang="scss" >
.diagnose{
	min-height: 100vh;
	background: #fff;
	.brandBanner{
		background: url(https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/diagnose1.jpg) center top repeat-x;
		min-height: 430px;
		margin-top: 60px;
		.text{
			color: #5177ff;
			text-align: center;
			h1{
				font-size: 80px;
				margin: 0;
				padding-top: 55px;
				font-weight: 700;
			}
			h2{
				font-size: 42px;
				padding-top: 60px;
				font-weight: 700;
				line-height: 54px;
			}
		}
	}
	.search{
		// width: 1500px;
		padding: 30px;
		padding-bottom: 20px;
		margin: 0 auto;
		// background: #fff;
		border-radius: 30px;
		// box-shadow:0 0 5px #ededed;
		.search-nei{
			width: 985px;
			margin: 0 auto;
		}
		.sfjjwtban{
			display: flex;
			justify-content: center;
		}
		.search-ts{
			font-size: 28px;
			text-align: center;
			color: #333;
			font-weight: 600;
			span{
				color: #5177ff;
				margin-left: 20px;
			}
		}
		.bannerxia{
			justify-content: center;
			display: flex;
			.soutishi{
				margin: 20px;
				padding: 20px;
				border-radius: 10px;
				background: #fff;
				text-align: center;
				margin-top: 33px;
				color: #666;
				font-size: 18px;
				font-weight: 600;
			}
		}
		
		.search-one{
			margin-top: 20px;
			display: flex;
			// box-shadow:  0 0 5px 1px #efefef;
			border-radius: 12px;
			overflow: hidden;
			.el-input{
				input{
					height: 70px;
					border: none;
					font-size: 18px;
					padding-left: 30px;
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
			.chaxun{
				font-size: 18px;
				background: #5177ff;
				cursor: pointer;
				color: #fff;
				position: relative;
				display: block;
				width: 195px;
				height: 70px;
				max-width: 250px;
				text-transform: uppercase;
				overflow: hidden;
				// border: 1px solid #5177ff;
				// border-top-right-radius: 10px;
				// border-bottom-right-radius: 10px;
				span{
					width: 100%;
					text-align: center;
					position: absolute;
					top: 23px;
					z-index: 1;
				}
				&:before, &:after {
					box-sizing: border-box;
					-webkit-transition: 0.5s;
					transition: 0.5s;
					z-index: 0;
				}
				&:after {
					content: '';
					width: 0;
					height: 0;
					-webkit-transform: rotate(360deg);
					border-style: solid;
					border-width: 0 0 0 0;
					border-color: transparent #cc55ff transparent transparent;
					position: absolute;
					top: 0;
					right: -10px;
				}
				&:before {
					content: '';
					width: 0;
					height: 0;
					-webkit-transform: rotate(360deg);
					border-style: solid;
					border-width: 0 0 0 0;
					border-color: transparent transparent transparent #cc55ff;
					position: absolute;
					bottom: 0;
					left: 0;
				}
				&:hover {
					color: #fff;
				}
				&:hover:before, &:hover:after {
					border-width: 80px 262px;
				}
				&:active {
					background: #697182;
				}
			}
		}
		.search-tow{
			display: flex;
			justify-content: space-between;
			padding:0 30px;
			padding-top: 30px;
			.el-carousel{
				width: 270px;
			}
			.medium{
				font-size: 14px;
				color: #5177ff;
				list-style:square;
			}
		}
	}
	.tabBrand1{
		width: 100%;
		height: 50px;
		background: #5177ff;
		.main{
			width: 1200px;
			margin: 0 auto;
			display: flex;
			.title{
				width: 190px;
				text-align: center;
				line-height: 50px;
				font-size: 18px;
				color: #eee;
				cursor: pointer;
				&.active{
					background: url(https://erlangcha.oss-cn-beijing.aliyuncs.com/dapingimg2/brand_3.png) no-repeat;
					margin-top: -21px;
					color: #fff;
					line-height: 60px;
					font-weight: 600;
				}
			}
		}
	}
	.brandContent{
		// width: 1200px;
		// margin: 0 auto;
		animation: brandContent1 2s ;
		@keyframes brandContent1 {
			0% {opacity: 0;}
			100% {opacity: 1;}
		}
		
		.brandbiaoge{
			padding:0;
		}
		.ldxltabal{
			.cell{
				text-align: center;
			}
			.spfwzs{
				height: 20px;
				overflow: hidden;
			}
		}
		
		.tabBrand{
			display: flex;
			justify-content: center;
			height: 60px;
			.title{
				width: 220px;
				height: 50px;
				text-align: center;
				line-height: 50px;
				font-size: 16px;
				color: #333;
				background: #fff;
				box-shadow: 0 0 5px 1px #ededed;
				border-radius: 10px;
				margin: 0 10px;
				transition:  all .3s;
				cursor: pointer;
				&.active{
					color: #fff;
					background: #5177ff;
					margin-top: -5px;
					transition:  all .3s;
				}
				&:hover{
					margin-top: -5px;
					transition:  all .3s;
				}
			}
		}
		.shangbiao{
			width: 1200px;
			margin: 0 auto;
			margin-top: 30px;
			.biaoge{
				display: flex;
				justify-content: space-between;
				.dan{
					width: 260px;
					height: 160px;
					border-radius: 10px;
					padding: 20px;
					cursor: pointer;
					&:nth-child(1){
						background: #ff97a1;
						&:hover{
							border-bottom: 5px solid #e27a92;
						}
					}
					&:nth-child(2){
						background: #8792f3;
						&:hover{
							border-bottom: 5px solid #4e5ff7;
						}
					}
					&:nth-child(3){
						background: #8ac3f8;
						&:hover{
							border-bottom: 5px solid #50a1ed;
						}
					}
					&:nth-child(4){
						background: #ffa897;
						&:hover{
							border-bottom: 5px solid #ff795e;
						}
					}
					&:nth-child(5){
						background: #ea97ff;
						&:hover{
							border-bottom: 5px solid #cd7ae2;
						}
					}
					
					.topico{
						display: flex;
						.tubiao{
							margin-top: 12px;
							width: 50px;
							height: 50px;
							line-height: 46px;
							color: #fff;
							border-radius: 100%;
							border: 2px solid #fff;
							text-align: center;
							i{
								font-size: 32px;
							}
						}
						.shuju{
							margin-left: 20px;
							color: #fff;
							.top-title{
								display: inline-block;
								font-size: 16px;
								padding-bottom: 3px;
								border-bottom: 1px solid #fff;
							}
							.num{
								font-size: 42px;
								font-weight: 600;
							}
						}
					}
					.accfor{
						margin-top: 10px;
						.acctitle{
							margin-bottom: 10px;
							font-size: 18px;
							color: #fff;
						}
						.el-progress-bar__outer{
							background: rgba(255, 255, 255, .2)
						}
					}
				}
			}
			.xiala{
				padding:10px 20px;
				display: flex;
				justify-content: center;
			}
			
			.gengd{
				cursor: pointer;
				color: #666;
				text-align: center;
				line-height: 40px;
			}
		}
		.shangpin{
			&.dangqian{
				width: 1200px;
				margin: 0 auto;
				top:0;
				.el-table{
					box-shadow: 0 0 5px 1px #ededed;
					.el-table__body-wrapper{
						padding-top: 0 ;
					}
				}
				
			}
			&.tablexia{
				box-shadow: none;
			}
			.el-table{
				position: relative;
				border-radius: 10px;
				.el-table__footer-wrapper{
					top: 59px;
					position: absolute;
					td{
						background: #ffdf97;
						.cell{
							font-size: 16px;
							font-weight: 600;
							color: #987015;
						}
					}
				}
				.el-table__body-wrapper{
					padding-top: 59px;
				}
			}
			.el-table th.is-leaf{
				border-bottom: 1px solid #EBEEF5;
			}
			.el-table__header-wrapper thead tr th{
				height: 50px;
			}
			.el-table__body{
				margin-top: 0;
				.el-table td{
					padding: 9px 0;
				}
			}
			.cell{
				text-align: center;
			}
			.spxinxi{
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				&:hover{
					color: #5177ff;
				}
			}
		}
		.lv-page{
			margin-right: auto;
		}
	}
	.saimalx{
		height: 330px;
		margin: 30px 0;
		.erweima{
			img{
				width: 200px;
			}
		}
		img{
			width: auto;
		}
		p{
			text-align: center;
		}
	}
	.biaotiq{
		text-align: center;
		color: #333;
		font-size: 32px;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.jiazaiz{
		width: 1200px;
		margin: 0 auto;
		padding: 50px 0;
	}
	.zaiyonon{
		margin: 0 auto;
		text-align: center;
		width: 100%;
		height: 80px;
		padding-top: 20px;
		display: flex;
		justify-content: center;
		position: fixed;
		z-index: 10;
		bottom: 0;
		background: url(https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/idxbottombj1.jpg) center center;
		.text-js{
			font-size: 42px;
			color: #fff;
			margin-right: 10px;
		}
		.anniu{
			margin-left: 30px;
			height: 45px;
			cursor: pointer;
			font-size: 18px;
			color: #fff;
			border-radius: 50px;
			display: inline-block;
			border: 2px solid #fff;
			padding: 10px 30px;
			&:hover{
				background: #fff;
				color:#005aff;
			}
		}
	}
	.sceneasdq{
		.tiaoquan{ margin: 0 auto; margin-top:30px; height: 3px; width: 600px; background: #ddd;border: 2px; position: relative; overflow: hidden;}
		.jindu{background: #f00;transition: all 1s; height: 3px; position: absolute;}
		.text{
			text-align: center;
			font-size: 24px;
			color: #666;
		}
		.el-progress{
			width: 600px;
			margin: 0 auto;
			margin-top: 30px;
		}
	}
}
.brandxx{
	width: 240px;
	&.chengpin{
		padding: 20px;
	}
	.xiangxi{
		padding-top: 10px;
		span{
			line-height: 24px;
			font-size: 12px;
			color: #777;
			cursor: pointer;
			&:hover{
				color: #5177ff;
			}
		}
	}
	.tisbt{
		color: #333;
		width: 200px;
		border-bottom: 1px solid #ededed;
		line-height: 30px;
		font-size: 14px;
		i{
			margin-right: 10px;
		}
	}
}
.diagonDialog{
	position: relative;
	padding-bottom: 10px;
	&.el-dialog{
		border-radius: 0 !important;
	}
	.el-dialog__body {
		padding: 0;
	}
	.el-dialog__header {
		background-color: #4277f5;
		padding: 18px 20px;
	}
	.el-dialog__title{
		color: #fff;
	}
	.el-dialog__headerbtn{
		top: 10px;
		.el-dialog__close{
			color: #fff;
		}
	}
	
	.tiaobiao{
		padding: 10px 20px 0 20px;
	    font-size: 14px;
		.text{
			color: #333;
		}
	}
	.tijiao {
		background: #ebecf5;
		margin-top: 20px;
		padding: 20px;
		text-align: center;
	}
	p{
		margin: 20px 0;
	}
	.text{
		text-align: center;
	}
	.erweimaa{
		// position: absolute;
		width: 200px;
		height: 200px;
		margin: 0 auto;
		// right: -200px;
		// bottom: 0;
	}
}
</style>
