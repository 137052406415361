<template>
	<div class="sceneasdq">
		<div class="text">{{jdttext}}</div>
		<el-progress :text-inside="true" :stroke-width="20" :percentage="jdqtime" status="exception" :format="format"></el-progress>
		<!-- <div class="tiaoquan">
			<div class="jindu" :style="'width:' + jdqtime + '%'"></div>
		</div> -->
	</div>
</template>
  
  
<script>
export default {
	props:{
		textwen:null,
		time:null,
	},
	data() {
		return {
			jdqtime:0,
			jdttext:'开始分析',
		};
	},
	switch:{
		
	},
  
	mounted(){
		this.onJduttime()
	},
	methods: {
		
		onJduttime(){
			setTimeout(()=>{this.jdqtime = Math.round(Math.random()*10),this.jdttext = '正在分析物流体验...'},this.time * .1)
			setTimeout(()=>{this.jdqtime = Math.round(Math.random()*20+10),this.jdttext = '正在分析商品体验...'},this.time * .3)
			setTimeout(()=>{this.jdqtime = Math.round(Math.random()*30+30)},this.time * .5)
			setTimeout(()=>{this.jdqtime = Math.round(Math.random()*(80-60)+60),this.jdttext = '正在分析商家服务...'},this.time * .7)
			setTimeout(()=>{this.jdqtime = Math.round(Math.random()*(100-80)+80)},this.time * .8)
			setTimeout(()=>{this.jdqtime = 100,this.jdttext = '分析完成,加载页面请稍等...'},this.time)
		},
		format(percentage) {
			return percentage === 100 ? '完成' : `${percentage}%`;
		}
	}
};
</script>
<style lang="scss" >

</style>
