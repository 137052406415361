<template>
	<div class="proposalDiag">
		<div class="shifou">
			<span class='text'>是否解决了您的问题：</span>
			<el-button class="yijiej" type="success" @click="sfjiejue = false" v-if="sfjiejue" round>已解决</el-button>
			<el-button class="yijiej" type="success" v-else round><i class="el-icon-check"></i></el-button>
			<el-button type="primary" @click="onShow()" round>未解决</el-button>
		</div>
		<el-dialog title="问题提交" :visible.sync="kefutan" width="700px" custom-class="diagonDialog" modal-append-to-body>
			<div class="tiaobiao">
				<p>请输入您需要解决的问题</p>
				<p><el-input type="textarea" :rows="3" placeholder="请输入您的建议" v-model="textarea"></el-input></p>
				<!-- <div class="erweimaa"><img width="100%" src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/zhaotianyuan.jpg" /></div>
				<p class="text">扫码联系客服咨询更多问题</p> -->
			</div>
			<div class="tijiao"><el-button type="primary" @click="onJianyitj">问题提交</el-button></div>
		</el-dialog>
	</div>
	
</template>
  
  
<script>
export default {
	data() {
		return {
			textarea:'',//建议
			kefutan:false,
			sfjiejue:true,//是否解决了您的问题
		};
	},
	switch:{
		
	},
  
	mounted(){
	},
	methods: {
		onShow(){
			this.kefutan = true
		},
		//建议提交
		onJianyitj(){
			if(this.textarea.length > 0){
				var param = {
					proposal_desc:this.textarea,
				}
				this.$service.post(this.$api.proposal,param, (res)=> {
					if(res.code == 200){
						this.$message({ message: '提交成功', type: 'success'});
						this.textarea = ''
						this.kefutan = false
					}
				})
			}else{
				this.$message({ message: '请输入您需要解决的问题', type: 'warning'});
			}
		}
	}
};
</script>
<style lang="scss" >
.proposalDiag{
	.shifou{
		color: #fff;
		.text{
			font-weight: 600;
			font-size: 16px;
		}
		.yijiej{
			width: 90px;
			height: 40px;
			padding: 0;
			transition: .5s;
		}
	}
}

</style>
